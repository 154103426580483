import React from "react"
import Link from "../components/common/Link"
import Ingress from "../components/index/Ingress"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TackPage = () => (
  <Layout>
    <SEO title="Tack för ditt meddelande" />
    <div className="min-h-screen flex  justify-center pt-16 text-center">
      <div className="flex flex-col gap-4 mt-24">
        <h1>Tack för ditt meddelande!</h1>
        <p>
          <Link to="/" className="underline">
            Tillbaka till startsidan
          </Link>
        </p>
      </div>
    </div>
    {/* <WhyMentis /> */}
  </Layout>
)

export default TackPage
